import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Kubelink - Kubernetes Managed Hosting Service</title>
                <meta name="description" content="Kubelink - Kubernetes Managed Hosting Service" />
                <meta name="og:title" property="og:title" content="Kubelink - Kubernetes Managed Hosting Service"></meta>
                <meta name="twitter:card" content="Kubelink - Kubernetes Managed Hosting Service"></meta>
                <meta property="og:image" content="https://kubelink-public.s3.nl-ams.scw.cloud/images/kubelink-logo-1844-1844.png" />
            </Helmet>
        </div>
    )
}

export default SEO
