import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Kubelink is a highly scalable, high performance container management service based on Kubernetes that supports Docker containers and allows you to easily run applications on a shared cluster.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>About Kubelink</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <Link to="/contact">
                                        <i className='bx bx-envelope'></i>
                                        Contact Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services-agreement">
                                    Services Agreement
                                    </Link>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@kubelink.com">info@kubelink.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <a href="https://console.kubelink.com">
                                        Control Panel
                                    </a>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://console.kubelink.com/tutorial">
                                        Getting Started
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Kubelink</strong> All rights reserved </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;